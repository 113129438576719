<template>
  <div class="dataCheck">
    <p>上传的EXCEL请参考下面例子！</p>
    <img src="../../assets/img/views/1.png" style="margin-top: 20px;" />
    <p style="margin: 20px 0;">上传附件</p>
    <el-upload class="avatar-uploader" action="https://test.yuqing.cn/api/report/excel/analyze"
      :headers="{ Authorization: token }" :on-success="handleAvatarSuccess" :on-error="handleAvatarError"
      :before-upload="beforeAvatarUpload" :limit="1">
      <i class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <p style="margin: 20px 0;">{{ text }}</p>
    <el-progress v-if="progress" :text-inside="true" style="width: 400px;" :stroke-width="26"
      :percentage="msg"></el-progress>
  </div>
</template>

<script>
import {
  exportData,
  downloadExportData
} from "../../api/dataCheck/index.js";
export default {
  data() {
    return {
      token: sessionStorage.getItem("token"),
      fileName: "",
      text: "",
      progress: false,
      msg: 0
    };
  },
  methods: {
    // 上传图片
    handleAvatarSuccess(response) {
      if (response.code == 200) {
        this.$message({
          message: "上传成功！请等待分析！",
          type: "success",
        });
        this.polling(response);
      } else {
        this.$message({
          message: "上传失败：" + response.msg,
          type: "error",
        });
      }
    },
    handleAvatarError() {
      this.$message({
        message: "上传失败",
        type: "error",
      });
    },
    //上传前 文件判断 只能是 PNG JPG MP4
    beforeAvatarUpload(file) {
      const isTYPE =
        file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel";
      if (!isTYPE) {
        this.$message.error(
          "上传附件只能是EXCEL!"
        );
      }
      this.fileName = file.name;
      this.text = `正在上传文件...请稍后！`;
      return isTYPE;
    },
    async polling(response) {
      const formData = new FormData();
      formData.append('fileName', this.fileName);
      formData.append('fileId', response.data.fileId);
      let res = await exportData(formData);
      if (res.data.code == 500) {
        let msg = JSON.parse(res.data.msg);
        if (msg.allCount === 0) {
          this.text = `数据处理中...请稍后！`;
        } else {
          this.text = `数据处理中...请稍后！已处理数据：${msg.count}。所有待处理数据：${msg.allCount}`;
          this.progress = true;
          this.msg = Math.round(msg.count / msg.allCount * 100);
        }
        setTimeout(() => {
          this.polling(response);
        }, 10000)
      } else if (res.data.code == 501) {
        this.text = "文档数据超过5000条,请整理数据后重新上传！";
        this.progress = false;
      } else {
        this.text = "正在下载...";
        this.progress = false;
        this.downloadFile(response);
      }
    },
    async downloadFile(response) {
      const formData = new FormData();
      formData.append('fileName', this.fileName)
      formData.append('fileId', response.data.fileId);
      let res = await downloadExportData(formData);
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = this.fileName;
      document.body.appendChild(a);
      a.style.display = "none";
      a.click();
      document.body.removeChild(a);
      this.text = "下载完成！";
    }
  },
  created() { },
  mounted() {

  },
};
</script>

<style scoped>
.dataCheck {
  padding: 20px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #d8d8d8;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background: #fff;
}

.avatar-uploader {
  margin-right: 24px;
  width: 300px;
}
</style>
